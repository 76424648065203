<template>
  <div class="healthbox">
  <div class="healthheader">
    <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane label="首页" name="first">
      <div> <img class="bg" src="./image/bg1.jpg">
      <div class="header-text">我的健康指数</div>
      </div>
    </el-tab-pane>
    <el-tab-pane label="我的健康指数" name="second">
      <div>
      <div class="number-box">
          <div class="input-box" ID="date-box">
            <div class="input-text"><i class="el-icon-date"></i>请选择日期</div>
            <div class="block">
            <el-date-picker
              v-model="value1"
              type="date"
              placeholder="请选择日期">
            </el-date-picker>
          </div>
          </div>
          <div class="input-box">
            <div class="input-text"><i class="el-icon-user"></i>身高</div>
             <el-input
              placeholder="请输入"
              suffix-icon="el-icon-user"
              v-model="input1">
            </el-input>
          </div>
          <div class="input-box">
            <div class="input-text"><i class="el-icon-goods"></i>体重</div>
             <el-input
              placeholder="请输入"
              suffix-icon="el-icon-goods"
              v-model="input2">
            </el-input>
          </div>
          <div class="input-box">
            <div class="input-text"><i class="el-icon-data-line"></i>伸缩压</div>
             <el-input
              placeholder="请输入"
              suffix-icon="el-icon-data-line"
              v-model="input3">
            </el-input>
          </div>
          <div class="input-box">
            <div class="input-text"><i class="el-icon-data-line"></i>舒张压</div>
             <el-input
              placeholder="请输入"
              suffix-icon="el-icon-data-line"
              v-model="input4">
            </el-input>
          </div>
          <div class="input-box">
            <div class="input-text"><i class="el-icon-set-up"></i>BMI</div>
             <el-input
              placeholder="请输入"
              suffix-icon="el-icon-set-up"
              v-model="input5">
            </el-input>
          </div>
          <div class="commit">
           保存记录
          </div>
      </div>
      </div>
    </el-tab-pane>
    <el-tab-pane label="我的变化" name="third">
     <div class="EchartPractice">
      <div id="main"></div>
     </div>
      <div class="EchartPractice">
       <div id="weight"></div>
     </div>
      
      
    </el-tab-pane>
    <el-tab-pane label="我的分享" name="fourth">
    <el-table
      :data="tableData1"
      style="width: 100%">
      <el-table-column
        prop="date"
        label="日期"
        width="180">
      </el-table-column>
      <el-table-column
        prop="share"
        label="心得">
      </el-table-column>
    </el-table>
    </el-tab-pane>
  </el-tabs>
  </div>
  </div>
</template>

<script>
export default {
  name: "HealthHome",
   data() {
      return {
          input1: '',
          input2: '',
          input3: '',
          input4: '',
        activeName: 'first',
         tableData: [{
         
          project: '身高',
          number: '157cm'
        }, {
          project: '体重',
          number: '64.9kg'
        }, 
        {
          project: '伸缩压',
          number: '12 mmHg'
        }, 
        {
          project: '舒张压',
          number: '0 mmHg'
        }, 
        {
          project: 'BMI',
          number: '26.33'
        }, 
        ],
         tableData1: [{
            date: '2021-05-02',
            share: '水煮蔬菜是减肥中最常见的一种方法，如果你能做到连续5天，早餐可以按照平时饮食习惯随意吃，在午餐的时候吃水煮菜，不过需要吃的全面有豆腐、木耳、香菇、粉丝等食物，在晚餐的时候只吃一个橙子，如果你能坚持一个月，那么脱胎换骨成为苗条身材，不是不可能的事情。'
          },
          {
            date: '2021-04-02',
            share:'要想快速瘦身，一定要先要管住自己的嘴，那么在这个时候，首先要做的就是需要尽量少吃，多食用低脂无糖的营养配餐，特别需要注意的是在睡前4个小时，要避免进食。同时最重要的是在烹饪的时候，用低脂植物又炒菜，这样可以避免过多摄入高脂肪的食物。'
          },
          {
            date: '2021-03-02',
            share:'随着社会经济的发展，人们生活压力也有越来越大了，于是越来越多的人为了节省早晨上班的时间，而不吃早餐，这样不仅容易引起肥胖症的发生，而且还会给身体带来健康隐患，因此早餐要尽量吃好，但是不要吃过于油腻的食物。'
          },
          {
            date: '2021-02-02',
            share:'对于女生来说零食不仅是一种填饱肚子的食物，更是一种饮食习惯，不管在看书的时候，还是与朋友闺蜜聊天的时候，总是少不了零食想随，这个时候宜选择萝卜、小黄瓜、苹果、梨子这样的蔬果类最为合适。'
          },
          {
            date: '2020-01-02',
            share:'晚餐后坚决不吃任何东西平时白天在公司总是随便吃点填饱肚子就好，加班工作也只简单的工作餐搞定，晚上回家后，即使妈妈做了丰盛的晚餐，为了苗条的身材，也一定要忍住坚决不吃!'
          },
          {
            date: '2020-05-02',
            share:'每天喝8杯水真的可以神奇地减少饥饿感、冲掉体内的多余脂肪，保持新陈代谢运作畅通，整个人都轻松许多，畅快许多，自然瘦下来了。'
          },
          {
            date: '2021-05-02',
            share: '水煮蔬菜是减肥中最常见的一种方法，如果你能做到连续5天，早餐可以按照平时饮食习惯随意吃，在午餐的时候吃水煮菜，不过需要吃的全面有豆腐、木耳、香菇、粉丝等食物，在晚餐的时候只吃一个橙子，如果你能坚持一个月，那么脱胎换骨成为苗条身材，不是不可能的事情。'
          },
          {
            date: '2021-04-02',
            share:'要想快速瘦身，一定要先要管住自己的嘴，那么在这个时候，首先要做的就是需要尽量少吃，多食用低脂无糖的营养配餐，特别需要注意的是在睡前4个小时，要避免进食。同时最重要的是在烹饪的时候，用低脂植物又炒菜，这样可以避免过多摄入高脂肪的食物。'
          },
          {
            date: '2021-03-02',
            share:'随着社会经济的发展，人们生活压力也有越来越大了，于是越来越多的人为了节省早晨上班的时间，而不吃早餐，这样不仅容易引起肥胖症的发生，而且还会给身体带来健康隐患，因此早餐要尽量吃好，但是不要吃过于油腻的食物。'
          },
          {
            date: '2021-02-02',
            share:'对于女生来说零食不仅是一种填饱肚子的食物，更是一种饮食习惯，不管在看书的时候，还是与朋友闺蜜聊天的时候，总是少不了零食想随，这个时候宜选择萝卜、小黄瓜、苹果、梨子这样的蔬果类最为合适。'
          },
          {
            date: '2020-01-02',
            share:'晚餐后坚决不吃任何东西平时白天在公司总是随便吃点填饱肚子就好，加班工作也只简单的工作餐搞定，晚上回家后，即使妈妈做了丰盛的晚餐，为了苗条的身材，也一定要忍住坚决不吃!'
          },
          {
            date: '2020-05-02',
            share:'每天喝8杯水真的可以神奇地减少饥饿感、冲掉体内的多余脂肪，保持新陈代谢运作畅通，整个人都轻松许多，畅快许多，自然瘦下来了。'
          },
          {
            date: '2021-05-02',
            share: '水煮蔬菜是减肥中最常见的一种方法，如果你能做到连续5天，早餐可以按照平时饮食习惯随意吃，在午餐的时候吃水煮菜，不过需要吃的全面有豆腐、木耳、香菇、粉丝等食物，在晚餐的时候只吃一个橙子，如果你能坚持一个月，那么脱胎换骨成为苗条身材，不是不可能的事情。'
          },
          {
            date: '2021-04-02',
            share:'要想快速瘦身，一定要先要管住自己的嘴，那么在这个时候，首先要做的就是需要尽量少吃，多食用低脂无糖的营养配餐，特别需要注意的是在睡前4个小时，要避免进食。同时最重要的是在烹饪的时候，用低脂植物又炒菜，这样可以避免过多摄入高脂肪的食物。'
          },
          {
            date: '2021-03-02',
            share:'随着社会经济的发展，人们生活压力也有越来越大了，于是越来越多的人为了节省早晨上班的时间，而不吃早餐，这样不仅容易引起肥胖症的发生，而且还会给身体带来健康隐患，因此早餐要尽量吃好，但是不要吃过于油腻的食物。'
          },
          {
            date: '2021-02-02',
            share:'对于女生来说零食不仅是一种填饱肚子的食物，更是一种饮食习惯，不管在看书的时候，还是与朋友闺蜜聊天的时候，总是少不了零食想随，这个时候宜选择萝卜、小黄瓜、苹果、梨子这样的蔬果类最为合适。'
          },
          {
            date: '2020-01-02',
            share:'晚餐后坚决不吃任何东西平时白天在公司总是随便吃点填饱肚子就好，加班工作也只简单的工作餐搞定，晚上回家后，即使妈妈做了丰盛的晚餐，为了苗条的身材，也一定要忍住坚决不吃!'
          },
          {
            date: '2020-05-02',
            share:'每天喝8杯水真的可以神奇地减少饥饿感、冲掉体内的多余脂肪，保持新陈代谢运作畅通，整个人都轻松许多，畅快许多，自然瘦下来了。'
          }
           ]
      };
    },
    methods: {
      handleClick(tab, event) {
        console.log(tab, event);
      },
      drawChart() {
        let myEchart = this.$echarts.init(document.getElementById("main"));
        let option = {
          title:{
            text:"我的BMI"
          },
          tooltip:{},
          legend:{
            data:["BMI"]
          },
          xAxis:{
            data: ["周一","周二","周三","周四","周五","周六","周日"]
          },
          yAxis:{},
          series:[{
            name:'BMI',
            type:"bar",
            data:[27.43,22.33,23.44,20.98,25.78,24.68]
          }]
        };
        myEchart.setOption(option);
        let myEchart1 = this.$echarts.init(document.getElementById("weight"));
        let option1 = {
          title:{
            text:"我的体重变化"
          },
          tooltip:{},
          legend:{
            data:["体重"]
          },
          xAxis:{
            data: ["周一","周二","周三","周四","周五","周六","周日"]
          },
          yAxis:{},
          series:[{
            name:'体重',
            type:"bar",
            data:[64,68,70,61,63,64,62]
          }]
        };
        myEchart1.setOption(option1);
      },
    },
    mounted() {
      this.drawChart();
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.healthheader{
  height:40px;
  width:100%;
  background-color: #B5CDCE;
}
.el-tabs >>>.el-tabs__header{
  margin:0px;
}
.el-tabs >>> .el-tabs__nav-scroll{
  padding-left:20px;
}
.bg{
  top:0;
  left:0;
  bottom:0;
  right:0;
  position:fixed;
  z-index:-1;
  height:100%;
  width:100%;
}
.header-text{
  font-size: 70px;
  font-weight: bold;
  margin-top:250px;
   width:450px;
  height:150px;
  background-color: #c5cdce94;
  line-height: 150px;
  border-radius: 20px;
  box-shadow:0px 0px 10px rgb(187, 184, 184);
  position: relative; /* 注意这是指盒子的左顶点在页面的中心 */
  left:50%; 
  margin-left: -225px;
  padding:20px;
}
.healthynumber{
  margin-top:15px;
}
.el-table{
  padding-left:20px;
}
#main {
    width: 600px;
    height:400px;
    margin: auto;
    margin-top: 100px
  }
#weight {
    width: 600px;
    height:400px;
    margin: auto;
    margin-top: 100px
  }
  .number-box{
    width:500px;
    height:1000px;
    position: relative; /* 注意这是指盒子的左顶点在页面的中心 */
    left:50%; 
    margin-left: -250px;
    margin-top:50px;
  }
  .input-box{
    display: flex;
    width:500px;
    background-color: #ACC6C7;
     border-radius: 10px;
    margin-top:15px;
  }
  .input-text{
    width:200px;
    line-height: 40px;
  
  }
  #date-box{
  margin-bottom: 15px;
   box-shadow:0px 0px 10px rgb(187, 184, 184);
  }
  .block{
    margin-left:100px;
  }
  i{
    margin-right:5px;
    color:rgb(6, 77, 6)
  }
  .commit{
   width:500px;
   height:50px;
   font-size: 20px;
   background-color: #ACC6C7;
   margin-top: 15px;
   line-height: 50px;
   border-radius: 20px;
    box-shadow:0px 0px 10px rgb(187, 184, 184);
  }
</style>
